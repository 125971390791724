import React, { FC, useRef } from 'react';
import { FormAssistantChatProps } from './interfaces';
import { ChatBubble } from './ChatBubble';
import { ChatInput } from './ChatInput';
import { Role } from '@wix/ambassador-forms-assistant-v1-message/types';
import { ChatHeader } from './ChatHeader';
import { useStickyElementWithinContainer } from '../../hooks/use-scrollable-element';
import { ChatActions } from './ChatActions';

import { classes } from './FromAssistantChat.st.css';

export const FormAssistantChat: FC<FormAssistantChatProps> = ({
  formValues,
  aiAssistantService,
  aiAssistantState,
  onClose,
  onSend,
  dataHook,
  formViewerHeight,
}) => {
  const chatElement = useRef<HTMLDivElement>(null);
  const { top } = useStickyElementWithinContainer(
    chatElement,
    formViewerHeight,
  );

  const submitUserMessage = (message: string) => {
    aiAssistantService.submitUserMessage({
      message,
      formValues,
    });
    onSend && onSend();
  };

  const lastMessageIsSkippable =
    !!aiAssistantState?.messages[0]?.context?.canBeSkipped &&
    !aiAssistantState?.assistantTyping;

  return (
    <div
      className={classes.container}
      data-hook={dataHook}
      ref={chatElement}
      style={{ top }}
    >
      <ChatHeader onClose={onClose} />
      <div className={classes.chatContainer}>
        {aiAssistantState.apiErrorOcurred && (
          <div className={classes.error}>Error in API</div>
        )}
        {aiAssistantState?.assistantTyping && (
          <ChatBubble creatorRole={Role.ASSISTANT} loading />
        )}
        {aiAssistantState?.messages?.map((message) => (
          <ChatBubble
            creatorRole={message.role}
            message={message.content}
            key={message.id}
          />
        ))}
      </div>
      <div className={classes.inputContainer}>
        <ChatInput
          onSubmit={submitUserMessage}
          disabled={aiAssistantState.assistantTyping}
        />
      </div>
      <ChatActions
        onClose={onClose}
        enableSkip={lastMessageIsSkippable}
        submitUserMessage={submitUserMessage}
      />
    </div>
  );
};
